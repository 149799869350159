import './style.css'

export default function Privacy() {
  return (
    <div className="privacy">
      <h1>s by Wooil Kim 개인정보처리방침</h1>
      <p>
        s by Wooil Kim(이하 “s”라 함)은 정보통신망 이용촉진 및 정보보호 등에
        관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법, 등
        정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을
        준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익
        보호에 최선을 다하고 있습니다.
      </p>
      <p>수집하는 개인정보의 항목 및 수집방법</p>
      <p>
        1) s는 일체의 회원가입 및 개인정보 제공을 요구하지 않으며 어떤 제한없이
        앱의 모든 기능을 이용할 수 있습니다.
      </p>
    </div>
  )
}
